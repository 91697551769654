input[type=text],
select,
textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

input[type=submit] {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #45a049;
}

.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;

}

#formParent {
    width: 80%;
    margin: auto;
}
.overlay {
left: 0;
top: 0;
width: 100%;
height: 100%;
position: fixed;

}

.overlay__inner {
left: 0;
top: 0;
width: 100%;
height: 100%;
position: absolute;
}

.overlay__content {
left: 50%;
position: absolute;
top: 50%;
transform: translate(-50%, -50%);
}

.spinner {
width: 100px;
height: 100px;
display: inline-block;
border-width: 8px;
border-color: rgba(255, 255, 255, 0.05);
border-top-color: rgb(32, 220, 117);
animation: spin 1s infinite linear;
border-radius: 100%;
border-style: solid;
}

@keyframes spin {
100% {
transform: rotate(360deg);
}
}

#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
  }